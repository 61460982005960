import React, { useState } from "react"
import axios from "axios"
import { base, URLS } from "../config/urls"
import { navigate } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import Footer from "../components/footer/footer"

const ForgotPassword = props => {
  const token = new URLSearchParams(props.location.search).get("token")
  const [phoneNumber, setPhoneNumber] = useState()
  const [otp, setOtp] = useState("")
  const [createPassword, setCreatePassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState()

  const [otpVerified, setOtpVerified] = useState(false)
  const [passwordVerified, setPasswordVerified] = useState(false)
  const [oneCapitalError, setOneCapitalError] = useState(false)
  const [oneLowerError, setOneLowerError] = useState(false)
  const [atLeastOneNumberError, setAtLeastOneNumberError] = useState(false)
  const [specialCharacterError, setSpecialCharacterError] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)

  const [otpError, setOtpError] = useState(null)
  const [error, setError] = useState(null)

  const sendOtp = () => {
    axios({
      method: "post",
      url: URLS.sendOTPUrl(),
      data: {
        mobile: phoneNumber
      }
    })
      .then(result => {
        setOtpVerified(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const verifyOtp = () => {
    axios({
      method: "post",
      url: URLS.verifyOTPUrl(),
      data: {
        mobile: phoneNumber,
        otp: otp
      }
    })
      .then(result => {
        setOtpError(null)
        setPasswordVerified(true)
      })
      .catch(error => {
        setOtpError(error.response.data.data.msg)
      })
  }

  const SavePassword = () => {
    axios({
      method: "post",
      url: URLS.forgotPassword(),
      data: {
        phone_number: phoneNumber,
        otp: otp,
        new_password: createPassword
      }
    })
      .then(result => {
        setError(null)
        navigate("/login")
      })
      .catch(error => {
        setError(error.response.data.msg)
      })
  }
  return (
    <Layout>
      <SEO title="Login"/>
      <>
        <div className="flex items-center justify-center pa6-ns pa4">
          <div className="w-100" style={{ maxWidth: "400px" }}>
            <div className="w-100">
              <h3>Forgot Password</h3>
              <div class="uk-margin">
                <input
                  className="uk-input"
                  placeholder={"Enter Phone number"}
                  value={phoneNumber}
                  onChange={e => {
                    setPhoneNumber(e.target.value)
                  }}
                />
              </div>
              <button className="uk-button" onClick={() => sendOtp()}>Send OTP</button>
            </div>
            <div className="w-100 mt4">
              <h3>Otp </h3>
              {otpError && <p style={{ color: "red" }}>{otpError}</p>}
              <div className="uk-margin">
                <input
                  className="uk-input"
                  disabled={!otpVerified}
                  placeholder={"Enter OTP"}
                  value={otp}
                  onChange={e => setOtp(e.target.value)}
                />
              </div>
              <button className="uk-button uk-button-secondary" onClick={() => verifyOtp()}>verify OTP</button>
            </div>
            <br/>
            <div className="w-100">
              {error && (
                <>
                  <p style={{ color: "red" }}>{error}</p>
                  <button className="uk-button" onClick={() => navigate("/login")}>Login</button>
                  <br/>
                </>
              )}
              <div className="uk-margin">
                <input
                  className="uk-input"
                  disabled={!passwordVerified}
                  placeholder="createPassword"
                  value={createPassword}
                  type="password"
                  onChange={e => {
                    let data = e.target.value
                    setOneCapitalError(/[A-Z]/.test(data))
                    setOneLowerError(/[a-z]/.test(data))
                    setAtLeastOneNumberError(/[0-9]/.test(data))
                    setSpecialCharacterError(/[,/<>!@#$%^&*_+=-]/.test(data))
                    setPasswordMatch(confirmPassword === data)
                    setCreatePassword(data)
                  }}
                />
              </div>
              <div className="uk-margin">
                <input
                  className="uk-input"
                  disabled={!passwordVerified}
                  placeholder="confirmPassword"
                  value={confirmPassword}
                  onChange={e => {
                    const data = e.target.value
                    setPasswordMatch(createPassword === data)
                    setConfirmPassword(data)
                  }}
                  type="password"
                />
              </div>
              <div className="uk-margin" style={{ color: "red" }}>
                {!oneCapitalError && <div>one capital letter</div>}
                {!oneLowerError && <div>one lower letter</div>}
                {!atLeastOneNumberError && <div> at least one number</div>}
                {!specialCharacterError && <div> at least one special character</div>}
                {!passwordMatch && <div>password is not match</div>}
              </div>
              <button className="uk-button uk-button-primary" onClick={() => SavePassword()}>Save</button>
            </div>
          </div>
        </div>
      </>
      <Footer/>
    </Layout>
  )
}

export default ForgotPassword
