import React from "react"

const Footer = () => (
  <div className="uk-padding uk-section-muted">
    <div className="">
      <h2 className="uk-text-primary">Contact Us</h2>
      <form action="" className="uk-form uk-grid uk-child-width-1-4@s uk-child-width-1-1" data-uk-grid>

        <div>
          <input type="text" placeholder="John Doe" className="uk-input"/>
        </div>

        <div>
          <input type="email" placeholder="youremail@domain.com" className="uk-input"/>
        </div>

        <div>
          <input type="text" placeholder="9xxxxxxxx19" className="uk-input"/>
        </div>

        <div>
          <button className="uk-button uk-button-primary uk-width-expand ">
            Send Message
          </button>
        </div>
        
      </form>
      <hr className="uk-hr" />
      <div className="flex justify-between flex-column flex-row-ns">
        <div>
          <div className="">All rights reserved @Treato Pvt. Ltd.</div>
        </div>
        <div>
          <div className="uk-text-primary mt2"><span className="mh2-ns"><a href="#">Privacy Policy</a></span><span className="mh2"><a href="#">T&C</a></span></div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
